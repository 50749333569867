<template>
  <div class="content">
    <base-header type="kakao" class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-kakao d-inline-block mb-0">사용자 관리</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0 d-inline">사용자 목록</h3>
            <div class="float-right">
              <base-button type="primary" block @click="openLayer">사용자 등록</base-button>
            </div>
          </template>
          <!-- 사용자 등록 레이어 -->
          <users-register-layer v-if="showLayer"
                                @close="closeLayer">
          </users-register-layer>

          <!-- 사용자 수정 레이어 -->
          <users-modify-layer v-if="showModifyLayer"
                              :seqno="seqno"
                              @close="closeLayer">
          </users-modify-layer>
          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <base-input additional-classes="width-100">
                <el-select
                  class="select-primary pagination-select"
                  v-model="pagination.perPage"
                  placeholder="Per page">
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </base-input>

              <div class="form-inline" style="margin-bottom: 1.5rem;">
                <div class="col-lg-4 pr-0">
                  <el-select v-model="search.condition"
                             filterable
                             placeholder="검색 조건">
                    <el-option v-for="option in selectOptions"
                               :key="option.label"
                               :label="option.label"
                               :value="option.value">
                    </el-option>
                  </el-select>
                </div>
                <div class="col-lg-8 pr-0">
                  <base-input group :input-group-classes="'width-100p'">
                    <input type="text" class="form-control" placeholder="검색어 입력" aria-label="검색어 입력" aria-describedby="button-addon2"
                           v-model="search.keyword"
                           @keydown.enter="getList(1)">
                    <div class="input-group-append">
                      <button class="btn btn-outline-primary" type="button" @click="getList(1)">검색</button>
                    </div>
                  </base-input>
                </div>
              </div>
            </div>

            <el-table :data="userList"
                      :header-row-class-name="'thead-light'"
                      :header-cell-style="{'font-weight':'bold','font-size':'0.85rem'}"
                      :empty-text="'등록된 사용자가 없습니다.'"
                      class="table-responsive table-flush">
              <el-table-column prop="name" label="사용자명" min-width="10%"></el-table-column>
              <el-table-column prop="status" label="상태" min-width="10%">
                <template slot-scope="scope">
                  <badge class="badge-dot mr-4" type="">
                    <i :class="statusClass(`${scope.row.status}`)"></i>
                    <span class="status">{{ scope.row.status | statusFilter }}</span>
                  </badge>
                </template>
              </el-table-column>
              <el-table-column prop="roleCode" label="권한" min-width="10%">
                <template slot-scope="scope">{{ scope.row.roleCode | roleCodeFilter }}</template>
              </el-table-column>
              <el-table-column prop="userId" label="아이디" min-width="30%">
                <template slot-scope="scope">
                  <div class="align-items-center">
                    <a href="#" @click="openModifyLayer(scope.row.seqno)"><u>{{ scope.row.userId }}</u></a>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="lastLoginDate" label="최근 로그인 일시" min-width="20%">
                <template slot-scope="scope">{{ scope.row.lastLoginDate | convertDatetimeFormat | nullToHyphen }}</template>
              </el-table-column>
              <el-table-column prop="regDate" label="등록 일시" min-width="20%">
                <template slot-scope="scope">{{ scope.row.regDate | convertDatetimeFormat }}</template>
              </el-table-column>
            </el-table>
          </div>
          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                총 <span class="text-danger font-weight-bold">{{ total }}</span> 건
              </p>
            </div>

            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @input="getList">
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { Table, TableColumn, Select, Option } from 'element-ui';
import commPaginationMixin from '@/shared/mixins/commPaginationMixin';
import { USER_API_PREFIX, STATUS_CODE } from '@/shared/util/const';
import UsersRegisterLayer from './UsersRegisterLayer';
import UsersModifyLayer from './UsersModifyLayer';

export default {
  mixins: [
    commPaginationMixin
  ],
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    UsersRegisterLayer,
    UsersModifyLayer
  },
  computed: {
    ...mapGetters({
      session: 'SessionStore/getSession'
    }),
  },
  filters: {
    /**
     * 권한 코드로 코드명 반환
     */
    roleCodeFilter: value => {
      let roleName = '';
      if ('A' === value) {
        roleName = '관리자';
      } else {
        roleName = '사용자';
      }
      return roleName;
    },
    /**
     * 상태 코드로 코드명 반환
     */
    statusFilter: value => {
      let statusName = '';
      switch (value) {
        case STATUS_CODE.AVAILABLE:
          statusName = '정상';
          break;
        case STATUS_CODE.LOCK:
          statusName = '잠김';
          break;
      }
      return statusName;
    }
  },
  created() {
    this.getList(1);
  },
  data() {
    return {
      showLayer: false,
      showModifyLayer: false,
      userList: [],
      seqno: undefined, // 사용자 수정 시, 선택된 사용자의 고유번호
      selectOptions: [
        {
          label: '사용자명',
          value: 'NAME'
        },
        {
          label: '아이디',
          value: 'USER_ID'
        }
      ],
      search: {
        condition: 'NAME',
        keyword: ''
      }
    };
  },
  methods: {
    /**
     * 등록 레이어 생성
     */
    openLayer() {
      this.showLayer = true;
    },
    /**
     * 수정 레이어 생성
     */
    openModifyLayer(seqno) {
      this.showModifyLayer = true;
      this.seqno = seqno;
    },
    /**
     * 레이어 닫기
     */
    closeLayer(payload) {
      this.showLayer = false;
      this.showModifyLayer = false;
      if(payload === 'Y') {
        this.getList();
      }
    },
    /**
     * 목록 조회
     */
    getList(page) {
      let pageNo = page ? page : this.pagination.currentPage;
      this.$axios.get(`${USER_API_PREFIX}/users`, {
        params: {
          page: pageNo,
          rows: this.pagination.perPage,
          searchCondition: this.search.condition,
          searchKeyword: this.search.keyword
        }
      }).then(res => {
        this.userList = res.data.result.userList; // 목록정보 세팅
        this.pagination.currentPage = pageNo; // 페이지 번호 세팅
        this.pagination.total = res.data.result.total; // 전체페이지수 세팅
      });
    },
    /**
     * 상태 클래스 반환
     * @param value
     * @returns {string}
     */
    statusClass(value) {
      let statusClass = '';
      switch (value) {
        case STATUS_CODE.AVAILABLE:
          statusClass = 'bg-success';
          break;
        case STATUS_CODE.LOCK:
          statusClass = 'bg-warning';
          break;
      }
      return statusClass;
    }
  }
};
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
