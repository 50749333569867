<template>
  <modal body-classes="pt-0 pb-0" @close="closeLayer">
    <h6 slot="header" class="modal-title">사용자 등록</h6>

    <template>
      <form role="form" class="needs-validation">
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">아이디 <span class="text-danger">*</span></label>
          <div class="col-md-9">
            <base-input v-model="model.userId"
                        name="userId"
                        :error="_getError('userId')"
                        :maxlength="50"
                        v-validate="'required|email'"
                        placeholder="아이디 (이메일) 입력"
                        autocomplete="username">
            </base-input>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">비밀번호 <span class="text-danger">*</span></label>
          <div class="col-md-9">
            <base-input v-model="model.userPassword"
                        type="password"
                        name="password"
                        ref="password"
                        :maxlength="20"
                        :error="_getError('password')"
                        v-validate="'required|password'"
                        placeholder="비밀번호 입력"
                        autocomplete="new-password">
            </base-input>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">비밀번호 확인 <span class="text-danger">*</span></label>
          <div class="col-md-9">
            <base-input placeholder="비밀번호 확인"
                        type="password"
                        name="confirmedPassword"
                        :maxlength="20"
                        :error="_getError('confirmedPassword')"
                        v-validate="'required|confirmed:password'"
                        v-model="model.confirmPassword"
                        autocomplete="new-password">
            </base-input>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">이름 <span class="text-danger">*</span></label>
          <div class="col-md-9">
            <base-input v-model="model.name"
                        name="name"
                        :error="_getError('name')"
                        :maxlength="10"
                        v-validate="'required'"
                        placeholder="이름 입력">
            </base-input>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">직무</label>
          <div class="col-md-9">
            <base-input v-model="model.duty"
                        :maxlength="10"
                        placeholder="직무 입력">
            </base-input>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">직위</label>
          <div class="col-md-9">
            <base-input v-model="model.position"
                        :maxlength="10"
                        placeholder="직위 입력">
            </base-input>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">전화번호</label>
          <div class="col-md-9">
            <base-input v-model="model.phoneNo"
                        placeholder="전화번호 입력"
                        :maxlength="13"
                        @keyup="phoneMask('phone')">
            </base-input>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">휴대폰번호 <span class="text-danger">*</span></label>
          <div class="col-md-9">
            <base-input v-model="model.mobileNo"
                        name="mobileNo"
                        :error="_getError('mobileNo')"
                        :maxlength="13"
                        v-validate="'required'"
                        placeholder="휴대폰번호 입력"
                        @keyup="phoneMask('mobile')">
            </base-input>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">OTP 인증 <span class="text-danger">*</span></label>
          <div class="col-md-9">
            <base-input>
              <base-radio v-model="model.otpYn" class="mt-1 mb-3" name="Y" :inline="true">예</base-radio>
              <base-radio v-model="model.otpYn" class="mt-1 mb-3" name="N" :inline="true">아니오</base-radio>
            </base-input>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">공지알림 <span class="text-danger">*</span></label>
          <div class="col-md-9">
            <base-input>
              <base-radio v-model="model.noticeYn" class="mt-1 mb-3" name="Y" :inline="true">수신</base-radio>
              <base-radio v-model="model.noticeYn" class="mt-1 mb-3" name="N" :inline="true">미수신</base-radio>
            </base-input>
          </div>
        </div>
      </form>
    </template>

    <template slot="footer">
      <base-button type="link" @click="closeLayer">닫기</base-button>
      <base-button type="primary" class="ml-auto" @click="processRegister">등록</base-button>
    </template>

    <p class="mt-0 mb-0">
      <small>비밀번호는 영문 대문자, 영문 소문자, 숫자, 특수문자 중 <span class="text-danger font-weight-bold">2종류 이상을 조합하여 최소 10자리 이상, 또는 3종류 이상을 조합하여 최소 8자리 이상, 최대 20자 길이</span>로 구성 해야 합니다.</small>
    </p>
    <p class="mb-0">
      <small>특수문자 중 <span class="text-danger font-weight-bold">" ' \ [ ] & Space(공백문자) ? % 사용하실 수 없습니다.</span></small>
    </p>
  </modal>
</template>

<script>
  import { Table, TableColumn, Select, Option } from 'element-ui';
  import commJsUtilMixin from '@/shared/mixins/commJsUtil';
  import commValidatorMixin from '@/shared/mixins/commValidatorMixin';
  import { USER_API_PREFIX, RESULT_CODE, STATUS_CODE } from '@/shared/util/const';

  export default {
    inject: ['$validator'], // vee-validate를 사용하게 위한 주입 진행
    name: 'UsersRegisterLayer',
    mixins: [ commJsUtilMixin, commValidatorMixin ],
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    data() {
      return {
        model: {
          userId: '',
          userPassword: '',
          confirmPassword: '',
          name: '',
          duty: '',
          position: '',
          phoneNo: '',
          mobileNo: '',
          status: STATUS_CODE.AVAILABLE,
          otpYn: 'N',
          noticeYn  : 'Y'
        }
      }
    },
    created() {
    },
    methods: {
      /**
       * 레이어 닫기 이벤트 실행
       */
      closeLayer() {
        this.$emit('close', 'N');
      },
      /**
       * 사용자 등록 실행
       */
      register() {
        this._swalQuestion({ title:'등록 하시겠습니까?' }, () => {
          this.$axios.post(`${USER_API_PREFIX}/users`, {
            userId: this.model.userId,
            userPassword: this.model.userPassword,
            name: this.model.name,
            duty: this.model.duty,
            position: this.model.position,
            phoneNo: this.model.phoneNo,
            mobileNo: this.model.mobileNo,
            otpYn: this.model.otpYn,
            noticeYn : this.model.noticeYn
          }).then(res => {
            if (res.data.result.code === RESULT_CODE.SUCCESS) {
              this.$emit('close', 'Y');
              this.notify('default', res.data.result.message);
            } else {
              this.notify('warning', res.data.result.message);
            }
          });
        });
      },
      /**
       * 등록 실행 전, validate check!
       */
      processRegister() {
        this._validateBeforeSubmit(this.register);
      },
      /**
       * 키 이벤트로 핸드폰 번호 형식으로 문자열 변경
       */
      phoneMask(target) {
        switch (target) {
          case 'mobile':
            this.model.mobileNo = this._autoHyphenPhoneNumber(this.model.mobileNo);
            break;
          case 'phone':
            this.model.phoneNo= this._autoHyphenPhoneNumber(this.model.phoneNo);
            break;
        }
      },
      /**
       * 알림 메시지 생성
       * @param type
       */
      notify(type = 'default', message) {
        let notiMsg = message ? message : '일치하는 계정 정보가 없습니다.';
        this.$notify({
          message: notiMsg,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      }
    }
  };
</script>

<style>
</style>
